import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Card,
  CardProps,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { ReactNode } from "react";

import { Markdown } from "../../molecules";

export type ComponentTitleCardProps = {
  title?: string;
  /** Component displayed in the right corner */
  endSlot?: ReactNode;
  /** Component to be displayed in the left corner */
  startSlot?: ReactNode;
  cardProps?: CardProps;
  infoText?: string;
  titleProps?: TypographyProps;
};

export const ComponentTitleCard: PimoReactComponent<
  ComponentTitleCardProps
> = ({
  title,
  endSlot,
  startSlot,
  cardProps = {},
  infoText,
  titleProps = {},
}) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        boxShadow: "none",
        height: "100%",
        width: "100%",
        "@media print": {
          border: "1px solid rgba(0, 0, 0, 0.12)",
        },
        borderBottom: "1px solid",
        borderColor: theme.palette.secondary.main,
        p: 0,
        borderRadius: "12px",
        ...cardProps.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          p: 0,
          height: 60,
        }}
      >
        {(startSlot || endSlot || title) && (
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 0,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                {startSlot}
                {title && (
                  <Typography
                    variant="h4"
                    sx={{
                      color: theme.palette.primary.contrastText,
                      ...titleProps.sx,
                    }}
                  >
                    {title}
                  </Typography>
                )}
              </Box>
              {endSlot}
            </Box>
          </Box>
        )}
        <Box sx={{ px: 2 }}>
          {infoText && (
            <Box
              sx={{
                background: "#f5f5f5",
                color: "#2B454E",
                width: "100%",
                justifyContent: "space-between",
                maxHeight: 400,
                overflow: "auto",
                fontStyle: "italic",
                borderRadius: "12px",
                display: "flex",
                flexDirection: "row",
                px: 2,
                mb: 2,
              }}
            >
              <Markdown>{infoText}</Markdown>
              <Box sx={{ height: "100%", mt: 2 }} id="scheme">
                <InfoOutlined sx={{ color: "#616161", height: "100%" }} />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default ComponentTitleCard;
